import "./pcis-banners.scss";

import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { PropsWithChildren } from "react";

interface PropsPcisBanners extends PropsWithChildren<any> {
  excludePciSlug?: string;
}

const PcisBanners: React.FC<PropsPcisBanners> = (props: PropsPcisBanners) => {
  const { allPcisJson, covers } = useStaticQuery(graphql`
    query pcibanners {
      allPcisJson {
        nodes {
          slug
          name
        }
      }
      covers: allFile(filter: { relativePath: { regex: "/cover-/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `);
  const filterPCIs = allPcisJson.nodes.filter((pci) => pci.slug !== props.excludePciSlug);

  function indexCover(name: string): number {
    return covers.nodes.findIndex((cover) => cover.name == `cover-${name}`);
  }

  return (
    <div className={"m-pcis-banners"}>
      {filterPCIs.map((pci, index) => (
        <div key={index} className={"m-pci-banner__card"}>
          <Link to={`/${pci.slug}/`}>
            <div className={"m-pci-banners__photo"}>
              <div className="m-map-frame"></div>
              {indexCover(pci.slug) !== -1 && (
                <GatsbyImage
                  image={covers.nodes[indexCover(pci.slug)].childImageSharp.gatsbyImageData}
                  alt={"Portada"}
                />
              )}
              {indexCover(pci.slug) === -1 && (
                <small style={{ color: "red" }}>
                  Carga en <code>src/assets/images/</code> una imagen de 640x640px con el nombre de cover-${pci.slug}
                  .jpg
                </small>
              )}
            </div>
            <h2 className={"m-pcis-banners__name"}>PCI {pci.name}</h2>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default PcisBanners;
