import "./pci.scss";

import { graphql } from "gatsby";
import { PageContext } from "gatsby/internal";
import * as React from "react";

import LazyIframe from "../../../components/common/iframe/lazyIframe";
import Layout from "../../../components/common/layout/layout";
// import { Contact } from "../../../components/Forms/Contact/Contact";
// import withForm from "../../../components/Forms/withForm";
import Gallery from "../../../components/Gallery/Gallery";
import Map from "../../../components/Map/Map";
import CourseMarkupSchema from "../../../components/SEO/CourseMarkupSchema";
import SEO from "../../../components/SEO/SEO";
import PcisBanners from "../../components/PCIsBanner/PcisBanners";
import Programming from "../../components/Programming/Programming";
import { PCI } from "../../domain/pci";

const PagePci: React.FC<PageContext> = (props: PageContext) => {
  const pci: PCI = props.pageContext.pci;
  // const ContactWithForm = withForm(Contact);

  function normalizeTextWithoutHyphen(text: string): string {
    return text.split("-")[1];
    //return text.replace(/[-|_]/g, " ").toLowerCase();
  }

  return (
    <>
      <SEO title={pci.name} canonical={`${pci.slug}`} description={pci.description} robots={"index, follow"} />
      <CourseMarkupSchema name={pci.name} slug={pci.slug} />
      <Layout>
        <div className={"o-pci"}>
          <main>
            <div className={"container"}>
              <div className="grid-x2 grid--bottom grid--break-pro">
                <div>
                  <h1>{pci.titleHero}</h1>
                  <h2>¿En que consiste el PCI de {pci.name}?</h2>
                  {pci.mainContent.blocks.map((block, index) => (
                    <p key={index}>{block}</p>
                  ))}
                </div>
                <div className={"is-relative"}>
                  <div className={"video--bloque--a"}></div>
                  <LazyIframe src={pci.mainContent.video} title={pci.name} />
                </div>
              </div>
              <section>
                <h2>Programación PCI {pci.name}</h2>
                {pci.descriptionProgram.map((block, index) => (
                  <p key={index}>{block}</p>
                ))}
                <Programming
                  specificModules={pci.specificModules}
                  commonModules={pci.commonModules}
                  workCenters={pci.workCenters}
                />
              </section>
            </div>
            <Gallery photos={props.data.allPhotos.nodes} />
            <div className="container">
              <h2>Documentos específicos</h2>
              <div className="grid-x2">
                <div>
                  {pci.documents.blocks.map((block, index) => (
                    <p key={index}>{block}</p>
                  ))}
                </div>
                <div>
                  <ul>
                    {props.data.allDownloads.nodes.map((document, index) => (
                      <li key={index}>
                        <a
                          href={`/downloads/${pci.slug}/${document.relativePath}`}
                          title={`Descargar ${document.relativePath}`}
                          target={"_blank"}
                          rel={"noreferrer noopener"}
                        >
                          {normalizeTextWithoutHyphen(document.name)}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </main>
        </div>
        <section>
          <div className={"container"}>
            <h2>Otros PCIs que puedes cursar en IES Ramón y Cajal</h2>
          </div>
          <PcisBanners excludePciSlug={pci.slug} />
        </section>
        <Map>
          <h2>¿Necesitas más información sobre PCI {pci.name} en Zaragoza?</h2>
          <p>
            Si tienes más dudas, envía un correo electrónico a la siguiente dirección{" "}
            <a href={"mailto:pci.ryc.zgz@gmail.com"} target={"_blank"} rel={"noopener noreferrer"}>
              {" "}
              pci.ryc.zgz@gmail.com{" "}
            </a>{" "}
            o rellena el siguiente formulario de información
          </p>
          {/*<ContactWithForm />*/}
        </Map>
      </Layout>
    </>
  );
};

export default PagePci;

export const query = graphql`
  query getImagesToGallery($regex: String!) {
    allPhotos: allFile(filter: { relativePath: { regex: $regex }, sourceInstanceName: { ne: "images" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    allDownloads: allFile(filter: { sourceInstanceName: { regex: $regex } }) {
      nodes {
        name
        relativePath
        extension
      }
    }
  }
`;
