import * as React from "react";
import { PropsWithChildren } from "react";
import Helmet from "react-helmet";

import { siteUrl } from "../../data/config";

interface PropsCoruse extends PropsWithChildren<any> {
  name: string;
  slug: string;
}

const CourseMarkupSchema: React.FC<PropsCoruse> = (props: PropsCoruse) => {
  const { name, slug } = props;
  const markup = `{
  "@context": "https://schema.org/",
  "@type": "Course",
  "name": "${name}",
  "provider": {
    "@type": "HighSchool",
    "name": "PCI IES Ramón y Cajal",
    "url": "${siteUrl}${slug}/"
  }
}`;
  return (
    <Helmet>
      <script type="application/ld+json">{markup}</script>
    </Helmet>
  );
};

export default CourseMarkupSchema;
