import "./programation.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

interface PropsProgramming extends PropsWithChildren<any> {
  specificModules: string[];
  workCenters: string[];
  commonModules: string[];
}

const Programming: React.FC<PropsProgramming> = (props: PropsProgramming) => {
  return (
    <section className={"m-programation"}>
      <div>
        <h3>Módulos específicos</h3>
        <ul>
          {props.specificModules.map((module, index) => (
            <li key={index}>{module}</li>
          ))}
        </ul>
      </div>
      <div>
        <h3>Aréas comunes</h3>
        <ul>{props.commonModules && props.commonModules.map((module, index) => <li key={index}>{module}</li>)}</ul>
      </div>
      <div>
        <h3>Cualificación Profesional</h3>
        <ul>
          {props.workCenters.map((module, index) => (
            <li key={index}>{module}</li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Programming;
