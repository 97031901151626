import "./gallery.scss";

import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { PropsWithChildren } from "react";
import ScrollContainer from "react-indiana-drag-scroll";

interface Photo {
  name: string;
  childImageSharp: { gatsbyImageData: any };
}

interface PropsGallery extends PropsWithChildren<any> {
  photos: Photo[];
}

const Gallery: React.FC<PropsGallery> = (props: PropsGallery) => {
  const photos_render: JSX.Element[] = [];

  function normalizeTextWithoutHyphen(text: string): string {
    return text.replace(/[-|_]/g, " ").toLowerCase();
  }

  props.photos.forEach((photo: Photo, index) => {
    photos_render.push(
      <div className={"m-gallery__frame"} key={index}>
        <div className={index % 2 === 0 ? "m-frame--a" : "m-frame--b"}></div>
        <GatsbyImage
          className={"a-gallery__photo"}
          alt={`foto de ${normalizeTextWithoutHyphen(photo.name)}`}
          image={photo.childImageSharp.gatsbyImageData}
        />
      </div>
    );
  });

  return (
    <section className={"o-gallery"}>
      <ScrollContainer className="scroll-container ">
        <div className={"scroll-container__content scroll-container__content--gallery"}>{photos_render}</div>
      </ScrollContainer>
    </section>
  );
};

export default Gallery;
