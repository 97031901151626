import "./map.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

const Map: React.FC = (props: PropsWithChildren<any>) => {
  return (
    <section>
      <div className={"container grid-x2 grid--bottom grid--break-tablet"}>
        <div>{props.children}</div>
        <div className={"is-relative"}>
          <div className={"m-map-frame"}></div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2981.0370695330466!2d-0.8931347845658881!3d41.654941079240515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd5914c0d7f19611%3A0xfc44df58ca933449!2sIES%20Ram%C3%B3n%20y%20Cajal!5e0!3m2!1ses!2ses!4v1640361070969!5m2!1ses!2ses"
            width="100%"
            height="400"
            style={{
              display: "block",
              width: "100%",
              border: "none",
            }}
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Map;
